<template>
  <div>
    <h4 v-html="$t('processing_form_rows_application_form_rows')"></h4>
    <v-row>
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-title>
            <span v-html="$t('processing_form_rows_show_all_rows')" />
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <v-row>
              <v-col class="processingFormRow" v-for="row in processingFormRows" v-bind:key="row.id" cols="6" sm="8" md="8" lg="6">
                <v-list-item>
                  <v-list-item-title
                    >{{ row.processingFormTemplatePartIdent }}.{{ row.processingFormTemplateGroupIdent }}.{{ row.block }}.{{ row.formRowTypeIdent }}
                  </v-list-item-title>
                </v-list-item>
                <p>
                  <v-btn size="sm" @click="deleteProcessingFormRow(row)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </p>
              </v-col>
            </v-row>
            <v-form @submit.prevent="onSubmit()">
              <v-col sm="">
                <v-text-field
                  id="fast"
                  ref="fastinput"
                  :label="$t('processing_form_template_edit_fast')"
                  v-model="fast"
                  type="text"
                  placeholder=""
                  autocomplete="off"
                ></v-text-field>

                <v-select
                  id="formParts"
                  required
                  v-model="formPart.id"
                  :items="formParts"
                  item-value="id"
                  :item-title="this.$getLangKey()"
                  :label="$t('processing_form_template_edit_formParts')"
                ></v-select>

                <v-select
                  id="formPartGroups"
                  :label="$t('processing_form_template_edit_formPartGroups')"
                  required
                  v-model="processingFormTemplateRow.formPartGroup.id"
                  :items="filteredformPartGroups"
                  item-value="id"
                  :item-title="this.$getLangKey()"
                ></v-select>

                <v-text-field
                  id="block"
                  disabled
                  :label="$t('processing_form_template_edit_block')"
                  v-model="processingFormTemplateRow.block"
                  type="number"
                  required
                ></v-text-field>

                <v-select
                  id="formRowTypes"
                  required
                  v-model="processingFormTemplateRow.formRowType.id"
                  :label="$t('processing_form_template_edit_formRowTypes')"
                  :items="filteredformRowTypes"
                  item-value="id"
                  :item-title="this.$getLangKey()"
                ></v-select>

                <p>
                  <span v-html="$t('processing_form_template_edit_product')" />:
                  {{ productCode }}
                </p>

                <!-- <v-checkbox id="bio" v-model="processingFormTemplateRow.bio" disabled :label="$t('processing_form_template_edit_bio')" name="bio-1" value="1" unchecked-value="0"></v-checkbox> -->

                <v-btn @click="back" color="secondary"><span v-html="$t('processing_form_template_edit_button_back')" /></v-btn>
                <v-btn type="submit" color="primary"><span v-html="$t('processing_form_rows_create_row')" /></v-btn>
              </v-col>
            </v-form>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </div>
</template>

<script lang="ts">
import { showError } from '@/services/axios'
import { app, apiURL } from '../main'

const processingFormTemplateRowMinimal = {
  processingFormTemplate: {
    id: null
  },
  formPartGroup: {
    id: null
  },
  formRowType: {
    id: null
  },
  product: {
    id: null
    //code: null,
  },
  block: 1,
  milkLabel: 'CONVENTIONAL'
}

import { defineComponent } from 'vue'
export default defineComponent({
  data() {
    return {
      apiURL: apiURL + '/processingFormTemplateRows/maxiFormRows',
      processingFormRowUrl: apiURL + '/processingFormTemplateRows',
      //model: defaultData,
      app: app,
      processingFormRows: [],
      fast: '',
      processingFormTemplate: undefined,
      processingFormTemplateRow: processingFormTemplateRowMinimal,
      formPart: {
        id: null
      },
      formPartGroup: { applicationFormPartId: null },
      formParts: [],
      formPartGroups: [],
      formRowTypes: [],
      filteredformPartGroups: [],
      product: []
    }
  },
  props: ['productId', 'productCode'],
  methods: {
    async loadProcessingFormRows() {
      try {
        const result = await this.axios.get(this.apiURL, {
          params: { productId: this.productId },
          headers: { Accept: 'application/json' }
        })
        if (await result) {
          return result.data
        }
      } catch (e) {
        showError(e)
      }
    },
    async deleteProcessingFormRow(row) {
      try {
        const result = await this.axios.delete(this.processingFormRowUrl, { params: { id: row.id } }, { headers: { 'Content-Type': 'application/json' } })
        if (await result) {
          this.processingFormRows = await this.loadProcessingFormRows()
        }
      } catch (e) {
        showError(e)
      }
    },
    back() {
      this.$router.go(-1)
    },
    async onSubmit() {
      this.processingFormTemplateRow.product = { id: this.productId }
      try {
        const result = await this.axios.post(
          apiURL + '/processingFormTemplateRows',
          { ...this.processingFormTemplateRow },
          { headers: { 'Content-Type': 'application/json' } }
        )
        if (await result) {
          this.processingFormRows = await this.loadProcessingFormRows()
          this.fast = ''
        }
      } catch (e) {
        showError(e)
      }
    },
    enableformPartGroupsByformPart(formPartId) {
      this.filteredformPartGroups = this.formPartGroups.filter((x) => x.applicationFormPartId === formPartId)
    },
    getformPartByIdent(ident) {
      const result = this.formParts.filter((elx) => elx.ident == ident)
      return result.length === 1 ? result[0].id : null
    },
    getformPartGroupsByIdent(ident) {
      const result = this.filteredformPartGroups.filter((elx) => elx.ident == ident)
      return result.length > 0 ? result[0].id : null
    },
    getformRowTypeByIdent(ident) {
      // ident starts with 0
      const result = this.filteredformRowTypes.filter((elx) => elx.ident === Number.parseInt(ident))
      return result.length > 0 ? result[0].id : null
    },
    getProductByCode(ident) {
      // ident starts with 0
      const result = this.product.filter((elx) => elx.code === ident)
      return result.length > 0 ? result[0].id : null
    }
  },
  computed: {
    filteredformRowTypes() {
      return this.formRowTypes.filter((x) => x.formPart.id === this.formPart.id)
    }
  },
  watch: {
    'formPart.id': {
      handler(id) {
        this.enableformPartGroupsByformPart(id)
      }
    },
    fast: {
      handler(value) {
        const elements = value.split('.')
        this.formPart.id = this.getformPartByIdent(elements[0])
        this.processingFormTemplateRow.formPartGroup.id = this.getformPartGroupsByIdent(elements[1])
        this.processingFormTemplateRow.formRowType.id = this.getformRowTypeByIdent(elements[3])
      },
      deep: true
    }
  },
  mounted() {
    this.$nextTick(async () => {
      this.processingFormRows = await this.loadProcessingFormRows()
      try {
        const templateResponse = await this.axios.get(apiURL + '/processingFormTemplates/findOrCreate', {
          headers: { Accept: 'application/json' },
          params: { dairyIdent: 0 }
        })
        if (await templateResponse) {
          const processingFormTemplate = templateResponse.data.content
          this.processingFormTemplate = processingFormTemplate
          this.processingFormTemplateRow.processingFormTemplate.id = this.processingFormTemplate[0].id
        }
      } catch (e) {
        showError(e)
      }

      try {
        const response = await this.axios.get(apiURL + '/formParts', {
          headers: { Accept: 'application/json' }
        })
        const data = await response.data
        this.formParts = data
        this.formParts.forEach((element) => {
          element.nameDe = `${element.nameDe} (${element.ident})`
          element.nameFr = `${element.nameFr} (${element.ident})`
          element.nameIt = `${element.nameIt} (${element.ident})`
        })
      } catch (e) {
        showError(e)
      }
      try {
        //http://localhost:8199/api/formPartGroups
        const response2 = await this.axios.get(apiURL + '/formPartGroups', {
          headers: { Accept: 'application/json' }
        })
        const data2 = await response2.data
        this.formPartGroups = data2
        this.formPartGroups.forEach((element) => {
          element.nameDe = `${element.nameDe} (${element.ident})`
          element.nameFr = `${element.nameFr} (${element.ident})`
          element.nameIt = `${element.nameIt} (${element.ident})`
        })
      } catch (e) {
        showError(e)
      }
      try {
        const response4 = await this.axios.get(apiURL + '/formRowTypes', {
          headers: { Accept: 'application/json' }
        })
        const data4 = await response4.data
        this.formRowTypes = data4
        this.formRowTypes.forEach((element) => {
          element.nameDe = `${element.nameDe} (${element.ident})`
          element.nameFr = `${element.nameFr} (${element.ident})`
          element.nameIt = `${element.nameIt} (${element.ident})`
        })
      } catch (e) {
        showError(e)
      }

      try {
        const response5 = await this.axios.get(apiURL + '/products', {
          /*params: { page: 0, size: 2000 },*/
          headers: { Accept: 'application/json' }
        })
        const data5 = await response5.data
        this.product = data5
        this.product.forEach((element) => {
          element.nameDe = `${element.nameDe} (${element.code})`
          element.nameFr = `${element.nameFr} (${element.code})`
          element.nameIt = `${element.nameIt} (${element.code})`
        })
      } catch (e) {
        showError(e)
      }
    })
  }
})
</script>

<style>
.processingFormRow {
  display: flex;
}

.list-group {
  text-align: left;
}
</style>
