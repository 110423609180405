<template>
  <v-row>
    <v-col cols="6" sm="12" md="6">
      <DbmDatePicker v-model="modelLocal.validFrom" :dateLabel="$t('editProductValidity_validFrom')" :required="true" :readonly="true" />
    </v-col>
    <v-col cols="6" sm="12" md="6">
      <DbmDatePicker v-model="modelLocal.validUntil" :dateLabel="$t('editProductValidity_validUntil')" :clearable="true" :readonly="dateIsReadOnly" />
    </v-col>
    <v-col cols="6" sm="12" md="6" v-if="this.modelLocal.ancestor">
      <span v-html="$t('status_validity_dates_ancestor_id')" />:
      {{ this.modelLocal.ancestor.id }}
    </v-col>
    <v-col cols="6" sm="12" md="6">
      <v-switch
        id="edit_product_status_validity_dates_publishable"
        v-model="modelLocal.publishable"
        color="primary"
        :label="$t('edit_product_status_validity_dates_publishable')"
      ></v-switch>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { app } from '@/main'
import BaseMixin from '../../../utils/BaseMixin'
import DbmDatePicker from '@/components/dbmDatePicker.vue'

import { defineComponent } from 'vue'
export default defineComponent({
  data() {
    return {
      app: app,
      apiURL: '/api/products/query',
      chosenProduct: {},
      errorMessage: '',
      dateIsReadOnly: true
    }
  },
  props: ['model'],
  components: {
    DbmDatePicker: DbmDatePicker
  },
  computed: {
    modelLocal: {
      get: function () {
        return this.model
      },
      set: function (value) {
        this.$emit('update', value)
      }
    }
  },
  mixins: [BaseMixin],
  mounted() {
    this.$nextTick(async () => {
      this.dateIsReadOnly = this.model.validUntil ? true : false
    })
  }
})
</script>
