<template>
  <v-container grid-list-xl>
    <h1 class="text-left product-title">
      <span v-html="$t('product_title')" /> {{ this.model.code }}
      {{ this.model[this.$getLangKey()] }}
    </h1>

    <v-form id="productForm" ref="productForm" @submit.prevent="submit()">
      <v-row>
        <!-- Bezeichnung -->
        <v-col class="item card-spacing" cols="12" md="6">
          <h2 v-html="$t('product_designation')"></h2>
          <ProductDescription v-model:model="model" :readonly="true" />
        </v-col>

        <!-- Status/Gültigkeit -->
        <v-col class="item card-spacing" cols="12" md="6" v-if="isLoaded">
          <h2 v-html="$t('product_status_validity')"></h2>
          <StatusValidityDates v-model:model="model" />
        </v-col>
        <!-- Tierart -->
        <v-col class="item card-spacing" cols="12" md="6">
          <h2 v-html="$t('product_animal_types')"></h2>
          <div class="animalTypesContainer">
            <v-row>
              <v-col cols="6" sm="12" md="6" lg="6">
                <v-checkbox v-model="model.animalTypeCow" :label="$t('cow')" :readonly="true"></v-checkbox>
                <v-checkbox v-model="model.animalTypeBuffalo" :label="$t('buffalo')" :readonly="true"></v-checkbox>
                <v-checkbox v-model="model.animalTypeGoat" :label="$t('goat')" :readonly="true"></v-checkbox>
                <v-checkbox v-model="model.animalTypeSheep" :label="$t('sheep')" :readonly="true"></v-checkbox>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <!-- Kategorisierung -->
        <v-col class="item card-spacing" sm="12" md="6">
          <h2 v-html="$t('product_categorization')"></h2>

          <ProductGroupTreeView v-model="model.productGroupMain.id" :rootId="1000" :title="$t('hauptgruppe')" :editable="false" />

          <ProductGroupTreeView v-model="model.productGroupEuroStatistic1.id" :rootId="5000" title="EuroStatistic1" :editable="false" />

          <ProductGroupTreeView v-model="model.productGroupEuroStatistic2.id" :rootId="6000" title="EuroStatistic2" :editable="false" />

          <ProductGroupTreeView v-model="model.productGroupSwissStatistic.id" :rootId="8000" title="SwissStatistic" :editable="false" />
        </v-col>
        <!-- Maxi-Formular  -->
        <v-col class="item card-spacing" sm="12" md="6">
          <router-link
            :to="{
              name: 'processingFormTemplates_update',
              params: { id: 1, tab: 1 }
            }"
            class="link"
          >
            <span v-html="$t('product_maxi_form')" />
          </router-link>
          <processingFormRows :productId="this.$route.params.id" :productCode="model.code" />
        </v-col>
        <!-- Allgemeine Gehaltswerte -->
        <v-col class="item card-spacing" sm="12" md="6" v-if="isRawMaterial">
          <h2 v-html="$t('product_raw_material')"></h2>
          <RawMaterialDetails v-model="model" />
        </v-col>

        <v-col class="item card-spacing" sm="12" md="6" v-if="isCheese">
          <h2 v-html="$t('product_cheese_details')"></h2>
          <CheeseDetails v-model="model" />
        </v-col>

        <v-col class="item card-spacing" sm="12" md="6" v-if="isCheese">
          <h2 v-html="$t('product_cheese_values')"></h2>
          <CheeseValues v-model="model" />
        </v-col>

        <v-col class="item card-spacing" sm="12" md="6">
          <h2 v-html="$t('product_general_content')"></h2>
          <GeneralNutritionalValues v-model="model" />
        </v-col>

        <v-col class="item card-spacing" sm="12" md="6" lg="6">
          <rdhComponent
            :show="$privileges.has({ path: '/masterData', permission: 'showRemarksDocumentsHistory' })"
            :remarks_show="true"
            :remarks_tag="'products_' + $route.params.id"
            :documents_show="true"
            :documents_tag="'products_' + $route.params.id"
            :history_show="true"
            :history_url="'/products/' + $route.params.id"
            ref="rdh"
          ></rdhComponent>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12">
          <v-btn color="secondary shadow-none" class="button-margin" @click="goBack"><span v-html="$t('product_edit_product_cancel')" /></v-btn>
          <v-btn color="primary shadow-none" class="button-margin" type="submit"><span v-html="$t('save')" /></v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
<script lang="ts">
import _ from 'lodash'
import productExample from './schemas/productExample.json'
import BaseMixin from '@/utils/BaseMixin'
import { showError } from '@/services/axios'
import { apiURL, emitter } from '@/main'
import { privileges } from '@/services/privileges'
import ProductDescription from './productDescription'
import StatusValidityDates from './statusValidityDates'
import ProductGroupTreeView from '../productGroups/productGroupTreeView.vue'
import processingFormRows from '@/components/processingFormRows'
import RawMaterialDetails from './rawMaterialDetails'
import CheeseDetails from './cheeseDetails'
import CheeseValues from './cheeseValues'
import GeneralNutritionalValues from './generalNutritionalValues'
import rdhComponent from '@/components/rdh/rdhComponent'

//https://www.npmjs.com/package/vue-form-schema
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'editProduct',
  data() {
    return {
      allItems: [],
      totalElements: 0,
      isLoaded: false,
      fields: <DTSHeader[]>[
        { key: 'creation', sortable: true },
        { key: 'creatorId', sortable: true },
        { key: 'guid', sortable: true },
        { key: 'hyid', sortable: true },
        { key: 'id', sortable: true },
        { key: 'ident', sortable: true },
        { key: 'modification', sortable: true },
        { key: 'modifierId', sortable: true },
        { key: 'nameDe', sortable: true },
        { key: 'nameFr', sortable: true },
        { key: 'nameIt', sortable: true },
        { key: 'parentId', sortable: true },
        { key: 'pos', sortable: true },
        { key: 'rootId', sortable: true },
        { key: 'valid_until', sortable: true }
      ],
      term: { nameDE: '', pos: '' },
      privileges: privileges,
      model: productExample,
      animalTypes: [],
      selectedAnimalType: undefined,
      selected: undefined
    }
  },
  mixins: [BaseMixin],
  methods: {
    submitAndClose() {
      this.submit()
      this.goBack()
    },
    goBack() {
      this.$router.go(-1)
    },
    async loadProduct(productId) {
      try {
        const result = await this.axios.get(apiURL + '/products/' + productId, { ...this.model }, { headers: { 'Content-Type': 'application/json' } })
        if (await result) {
          this.model = result.data
          this.isLoaded = true
          return result.data
        }
      } catch (e) {
        showError(e)
      }
    },
    async submit() {
      if (!(await this.$refs.productForm.validate()).valid) return
      try {
        const result = await this.axios.patch(
          apiURL + '/products/' + this.model.id,
          _.pick(this.model, _.keys(productExample), {
            headers: { 'Content-Type': 'application/json' }
          })
        )
        if (await result) {
          emitter.emit('toastSuccess', {
            message: this.$t('toast_save_success_message')
          })
          emitter.emit('loadHistory')
        }
      } catch (e) {
        showError(e)
      }
    },
    reset() {
      // hook
    }
  },
  computed: {
    isCheese() {
      return this.model.productGroupMain.parentId === 11
    },
    isRawMaterial() {
      return this.model.productGroupMain.parentId === 12
    }
  },
  components: {
    ProductDescription: ProductDescription,
    StatusValidityDates: StatusValidityDates,
    processingFormRows: processingFormRows,
    RawMaterialDetails: RawMaterialDetails,
    CheeseDetails: CheeseDetails,
    CheeseValues: CheeseValues,
    GeneralNutritionalValues: GeneralNutritionalValues,
    ProductGroupTreeView: ProductGroupTreeView,
    rdhComponent: rdhComponent
  },
  watch: {
    model: {
      handler(to) {
        // calculated fields https://tsmtreuhand.atlassian.net/wiki/spaces/dbmilch2020/pages/245694475/Milchverwertung+Produkte+Produkt
        to.equivalentMin3 = to.equivalentMin?.toFixed(3)
        to.equivalentMax3 = to.equivalentMax?.toFixed(3)
        to.equivalentAvg3 = to.equivalentAvg?.toFixed(3)
      },
      deep: true
    }
  },
  mounted() {
    this.$nextTick(async () => {
      const productId = this.$route.params.id
      this.product = await this.loadProduct(productId)
    })
  }
})
</script>

<style lang="scss" scoped>
@import '@/styles/colors.scss';
@import '@/styles/variables.scss';

option:disabled {
  background-color: rgba(192, 192, 192, 0.788);
}

.product-title {
  margin-bottom: $spacer-xs !important;
}

.card-spacing {
  padding-top: $spacer-xxxs !important;
  padding-bottom: $spacer-xxxs !important;
}

.button-margin {
  margin-right: $spacer-xs !important;
}
</style>
